import useIsMount from "@tamarack/shared/hooks/useIsMount";
import type {
  CSSProperties,
  FocusEventHandler,
  HTMLAttributes,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from "react";
import { forwardRef, useEffect, useId, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Tooltip from "../Tooltip";
import ErrorRoundedIcon from "../icons/ErrorRoundedIcon";
import LockIcon from "../icons/LockIcon";
import styles from "./styles.module.css";

type HTMLInputProps = HTMLProps<HTMLInputElement>;

export type InputProps = Omit<HTMLInputProps, "size" | "label" | "disabled" | "autoFocus"> & {
  /**
   * Set the focus on the input when it is rendered
   */
  autoFocus?: boolean;
  /**
   * Render and icon after the text input area
   */
  afterIcon?: ReactNode;
  /**
   * Render and icon before the text input area
   */
  beforeIcon?: ReactNode;
  /**
   * Manually style the containing element
   */
  containerStyle?: CSSProperties;
  /**
   * Set the input as disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Show a tooltip when the input is disabled
   */
  disabledWithTooltip?: string;
  /**
   * Render help text related to the input
   */
  helpText?: ReactNode | string;
  /**
   * Where the help text renders
   * @default end
   */
  helpTextPlacement?: "start" | "end";
  /**
   * Style the actual input element
   */
  inputClassName?: string;
  /**
   * Provide a ref to the input element
   */
  inputRef?: RefObject<HTMLInputElement>;
  /**
   * Manually set the validity of the input
   */
  forceValidity?: Validity;
  /**
   * The message to show when the input is invalid
   */
  invalidMessage?: string;
  /**
   * Render a label for the input
   */
  label?: string | ReactNode;
  /**
   * Style the label element
   */
  labelClassName?: string;
  /**
   * Where to place the label relative to the input
   * @default above
   */
  labelPlacement?: LabelProps["placement"];
  /**
   * Render a loading indicator
   * @default false
   */
  loading?: boolean;
  /**
   * Text to show in a tooltip when the user hovers over a read only input
   */
  readOnlyTooltip?: string;
  /**
   * The size of the element
   * @default medium
   */
  size?: "medium" | "large" | "touch";
  /**
   * Select all text in the input when clicking into it
   * @default true
   */
  selectOnFocus?: boolean;
};

export type Validity = "clean" | "valid" | "invalid";
export type OnBlur = FocusEventHandler<HTMLInputElement> | undefined;
export type OnFocus = FocusEventHandler<HTMLInputElement> | undefined;

const Input = forwardRef(
  (
    {
      afterIcon,
      autoFocus,
      beforeIcon,
      className,
      containerStyle,
      disabled,
      disabledWithTooltip,
      forceValidity,
      helpText,
      helpTextPlacement = "end",
      inputClassName,
      inputRef: externalInputRef,
      invalidMessage,
      label,
      labelClassName,
      labelPlacement,
      loading,
      onBlur,
      onFocus,
      readOnly,
      readOnlyTooltip,
      selectOnFocus = true,
      size,
      step,
      ...inputProps
    }: InputProps,
    ref
  ) => {
    const sizeClassName = styles[`input-${size}`];
    const labelSizeClassName = styles[`label-${size}`];
    const defaultInputId = useId();
    const inputId = inputProps.id ?? defaultInputId;
    const [validity, setValidity] = useState<Validity>("clean");

    const internalInputRef = useRef<HTMLInputElement>(null);
    const inputRef = externalInputRef ?? internalInputRef;
    const isMount = useIsMount();

    useEffect(() => {
      if (internalInputRef.current) {
        const isValid = internalInputRef.current.checkValidity();
        setValidity(isValid ? "valid" : "invalid");
      }
    }, [inputProps.required]);

    /**
     * Reset the validity when the input is disabled so the invalid styles don't ever show
     * on a disabled input
     */
    useEffect(() => {
      if (disabled) {
        setValidity("clean");
      }
    }, [disabled]);

    useEffect(() => {
      if (forceValidity === "invalid") {
        setValidity("invalid");
        inputRef.current?.setCustomValidity(invalidMessage ?? "");
      } else {
        inputRef.current?.setCustomValidity("");
        setValidity("valid");
      }
    }, [forceValidity, invalidMessage]);

    useEffect(() => {
      if (autoFocus && isMount) {
        setTimeout(() => inputRef.current?.focus());
      }
    }, [autoFocus, isMount]);

    const handleBlur: OnBlur = (e) => {
      onBlur?.(e);
      const element = e.currentTarget;

      const inputIsValid = element.willValidate ? element.checkValidity() : "clean";

      setValidity(inputIsValid ? "valid" : "invalid");
    };

    const handleFocus: OnFocus = (e: any) => {
      onFocus?.(e);

      if (selectOnFocus) {
        e.target.select();
      }

      setValidity("clean");
    };

    const isDisabled = (disabled ?? !!disabledWithTooltip) || loading;
    /**
     * type="number" doesn't like decimals unless you explicitly set the step to any or "0.01"
     * This will do this automatically if there is no step defined
     */
    const internalStep = inputProps.type === "number" && !step ? "any" : step;

    const inputElementRaw = (
      <div
        ref={ref as RefObject<HTMLDivElement>}
        className={twMerge(
          styles.input,
          "relative flex w-full items-center rounded-lg border border-tm-secondary-lighter leading-none hover:border-tm-secondary",
          sizeClassName,
          isDisabled && !label ? "cursor-not-allowed opacity-30" : undefined,
          isDisabled ? "cursor-not-allowed hover:border-tm-secondary-lighter" : undefined,
          loading && !label ? "cursor-wait" : undefined,
          loading ? "loading cursor-wait" : undefined,
          validity === "invalid" ? "animate-horizontalShake border-tm-error" : undefined,
          readOnly ? "cursor-not-allowed border-tm-divider hover:border-tm-divider" : undefined,
          className
        )}
        style={{
          ...(validity === "invalid"
            ? { boxShadow: "inset 0 0 0 3px rgba(255,0,0,0.2)" }
            : undefined),
          ...containerStyle,
        }}
      >
        <>
          {beforeIcon ? <span className="pl-1">{beforeIcon}</span> : null}
          <input
            {...inputProps}
            step={internalStep}
            readOnly={readOnly}
            ref={inputRef}
            id={inputId}
            disabled={isDisabled ?? loading}
            autoComplete="off"
            className={twMerge(
              "px-1.5",
              isDisabled ? "cursor-not-allowed" : undefined,
              loading ? "cursor-wait" : undefined,
              readOnly
                ? "pointer-events-none cursor-not-allowed opacity-70 focus-visible:outline-none"
                : undefined,
              inputClassName
            )}
            onBlur={handleBlur}
            onFocus={handleFocus}
            tabIndex={readOnly ? -1 : undefined}
            onInvalid={
              invalidMessage
                ? (e) => {
                    e.currentTarget.setCustomValidity(invalidMessage);
                    inputProps.onInvalid?.(e);
                  }
                : undefined
            }
            onChange={(e) => {
              // Need to reset the validity message so it isn't marked as invalid anymore
              e.currentTarget.setCustomValidity("");
              inputProps.onChange?.(e);
            }}
            onWheel={(event) => {
              if (inputProps.type === "number") {
                event.currentTarget.blur();
              }
            }}
            data-1p-ignore // Ignore 1Password autofill. If we want autofill, we can manually add it to each input
          />
          {readOnly ? (
            <span>
              <LockIcon className="mr-2 opacity-30" />
            </span>
          ) : null}
          {afterIcon ? <span className="pr-1">{afterIcon}</span> : null}
        </>
      </div>
    );

    const inputElement =
      disabledWithTooltip || readOnly ? (
        <Tooltip
          className={twMerge(
            isDisabled ? "cursor-not-allowed" : undefined,
            loading ? "cursor-wait" : undefined
          )}
          title={readOnly ? readOnlyTooltip ?? "This field cannot be edited" : disabledWithTooltip}
        >
          {inputElementRaw}
        </Tooltip>
      ) : (
        inputElementRaw
      );

    return label ? (
      <Label
        loading={loading}
        disabled={isDisabled}
        htmlFor={inputId}
        className={twMerge(labelSizeClassName, labelClassName)}
        label={label}
        error={validity === "invalid" ? invalidMessage : undefined}
        required={inputProps.required}
        placement={labelPlacement}
      >
        {inputElement}
        {helpText ? (
          <span
            className={twMerge(
              "flex pt-0.25 text-sm",
              helpTextPlacement === "end" ? "justify-end" : "justify-start"
            )}
          >
            {helpText}
          </span>
        ) : null}
      </Label>
    ) : (
      <>
        {inputElement}
        {helpText ? (
          <span
            className={twMerge(
              "pt-0.25 text-sm text-tm-secondary-lighter",
              helpTextPlacement === "end" ? "justify-end" : "justify-start"
            )}
          >
            {helpText}
          </span>
        ) : null}
      </>
    );
  }
);

export default Input;

export type LabelProps = PropsWithChildren &
  HTMLAttributes<HTMLLabelElement> & {
    htmlFor?: string;
    className?: string;
    label?: string | ReactNode;
    disabled?: boolean;
    loading?: boolean;
    error?: ReactNode | string;
    required?: boolean;
    placement?: "before" | "above";
  };

export const Label = ({
  error,
  loading,
  children,
  htmlFor,
  className,
  label,
  disabled,
  required,
  placement,
  ...labelProps
}: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      {...labelProps}
      className={twMerge(
        "flex w-full flex-col gap-0.25",
        placement === "before" ? "flex-row items-center justify-between gap-1" : undefined,
        disabled ? "cursor-not-allowed opacity-30" : undefined,
        loading ? "cursor-wait opacity-70" : undefined,
        className
      )}
    >
      <span
        className={twMerge(
          "flex items-center gap-1",
          styles.labelText,
          error ? "text-tm-error-text" : undefined
        )}
      >
        <span className="flex items-center gap-0.5">
          {label}
          {required ? (
            <Tooltip title="This field is required">
              <span className="cursor-help">*</span>
            </Tooltip>
          ) : null}
        </span>

        {error ? (
          <Tooltip title={error} color="error">
            <span className="text-tm-error-text">
              <ErrorRoundedIcon size={16} />
            </span>
          </Tooltip>
        ) : null}
      </span>

      {children}
    </label>
  );
};
