import { Slot } from "@radix-ui/react-slot";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import type { LabelProps } from "../Input";
import { Label } from "../Input";
import type { TooltipProps } from "../Tooltip";
import Tooltip from "../Tooltip";
import styles from "./styles.module.css";

type ButtonAttributes = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size">;

export type Size = "xsmall" | "small" | "medium" | "large" | "touch" | "touch-small";
export type ButtonVaraint =
  | "primary"
  | "secondary"
  | "tertiary"
  | "input"
  | "text"
  | "danger"
  | "info"
  | "link"
  | "badge"
  | "badge-success"
  | "badge-info"
  | "badge-warning"
  | "badge-danger";

type BaseButtonProps = ButtonAttributes & {
  /**
   * Text alignment in button
   */
  align?: "left" | "center" | "right";
  /**
   * Forces the ONLY child of the button to inherit the button's styles
   */
  asChild?: boolean;
  /**
   * Smaller button
   */
  condensed?: boolean;
  /**
   * Icon to be displayed at the end of the button
   */
  endIcon?: ReactNode;
  /**
   * Stretch the button to the full width of the parent
   */
  fullWidth?: boolean;
  /**
   * Message to be displayed when there is some sort of invalid state
   */
  invalidMessage?: ReactNode;
  /**
   * Label to be displayed above the button. Useful for making buttons look like inputs.
   * This is used by the Select components.
   */
  label?: string | ReactNode;
  /**
   * Where the label gets rendered
   * @default above
   */
  labelPlacement?: LabelProps["placement"];
  /**
   * Show button as loading
   */
  loading?: boolean;
  /**
   * Show a description when the button is loading in a tooltip
   */
  loadingDescription?: string | ReactNode;
  /**
   * Is the value on this button required in the form. Mostly used when the button is meant
   * too look like an input.
   */
  required?: boolean;
  /**
   * Shape of the button
   */
  shape?: "square" | "circle";
  /**
   * Size of the button
   */
  size?: Size;
  /**
   * Icon to be displayed at the start of the button
   */
  startIcon?: ReactNode;
  /**
   * Where to place the tooltip
   */
  tooltipPlacement?: TooltipProps["placement"];
  /**
   * Dictates what the button looks like and/or what it's used for
   */
  variant?: ButtonVaraint;
  /**
   * Adds a tooltip to the button
   */
  withTooltip?: string | ReactNode;
};

type ButtonEnabledProps = BaseButtonProps & {
  disabled?: undefined | false;
  disabledDescription?: undefined;
};
type ButtonDisabledProps = BaseButtonProps & {
  /**
   * Disables the button
   */
  disabled: boolean;
  /**
   * Adds a tooltip with a description when the button is disabled
   */
  disabledDescription: ReactNode;
};

export type ButtonProps = ButtonEnabledProps | ButtonDisabledProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      size = "medium",
      condensed = false,
      loading = false,
      className,
      asChild,
      children,
      disabledDescription,
      loadingDescription,
      disabled,
      type = "button",
      shape = "square",
      align = "center",
      fullWidth = false,
      label,
      labelPlacement,
      withTooltip,
      tooltipPlacement,
      required,
      invalidMessage,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const variantClassName = styles[`button-${variant}`];
    const sizeClassName = styles[`button-${size}`];
    const condensedClassName = condensed ? styles["button-condensed"] : undefined;

    // Compose all button styles
    const classNames = twMerge(
      styles.button,
      variantClassName,
      /**
       * Identifies the button as a badge for styling purposes with a high specificity
       */
      variant.includes("badge") ? styles.badge : undefined,
      sizeClassName,
      condensedClassName,
      loading ? styles.loading : undefined,
      shape === "circle" ? "rounded-full" : "rounded-lg",
      align == "center" ? "!justify-center" : align == "left" ? "!justify-start" : "!justify-end",
      fullWidth ? "w-full" : undefined,
      invalidMessage ? "!border-tm-error animate-horizontalShake" : undefined,
      className
    );

    const Component = asChild ? Slot : "button";

    const component = (
      <Component
        {...props}
        className={classNames}
        type={type}
        aria-disabled={disabled || loading || undefined}
        tabIndex={disabled ? -1 : props.tabIndex}
        ref={ref}
        style={{
          ...(invalidMessage ? { boxShadow: "inset 0 0 0 3px rgba(255,0,0,0.2)" } : undefined),
          ...props.style,
        }}
        /**
         * Disabled buttons get no mouse interactions, but we still want to show a tooltip, so
         * this emulates it.
         *
         * Firefox doesnt like onMouseDown. It prefers onClick.
         */
        onClick={disabled || loading ? (e) => e.preventDefault() : props.onClick}
      >
        {children}
      </Component>
    );

    const inputWithOrWithoutTooltip =
      disabledDescription && disabled ? (
        <Tooltip placement={tooltipPlacement} title={disabledDescription}>
          {component}
        </Tooltip>
      ) : loading && loadingDescription ? (
        <Tooltip placement={tooltipPlacement} title={loadingDescription}>
          {component}
        </Tooltip>
      ) : withTooltip ? (
        <Tooltip placement={tooltipPlacement} title={withTooltip}>
          {component}
        </Tooltip>
      ) : (
        component
      );

    return label ? (
      <Label
        loading={loading}
        disabled={disabled || loading}
        label={label}
        required={required}
        error={invalidMessage}
        placement={labelPlacement}
      >
        <span className="flex-grow">{inputWithOrWithoutTooltip}</span>
      </Label>
    ) : (
      inputWithOrWithoutTooltip
    );
  }
);

export default Button;

export const generateDisabledProps = ({
  disabled,
  disabledDescription,
}: {
  disabled: ButtonProps["disabled"];
  disabledDescription: ButtonProps["disabledDescription"];
}) => {
  return {
    disabled: disabled === undefined ? false : disabled,
    disabledDescription: disabled ? disabledDescription : undefined,
  };
};
