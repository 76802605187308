import type { CSSProperties, ReactNode, SVGProps } from "react";

export type IconProps = {
  className?: string;
  /**
   * @default 18
   */
  size?: number;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  role?: string;
};

export type SvgIconProps = IconProps &
  SVGProps<SVGSVGElement> & {
    paths: ReactNode;
    enableBackground?: string;
  };

export default function SvgIcon({
  className,
  size = 18,
  paths,
  style,
  enableBackground,
  onClick,
  onMouseDown,
  role,
  ...svgProps
}: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      onMouseDown={onMouseDown}
      role={role}
      className={className}
      enableBackground={enableBackground}
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="currentColor"
      style={style}
      {...svgProps}
    >
      {paths}
    </svg>
  );
}
